import getValue from './getValue';
import sha256 from './sha256';

const addUserIds = dmps => {
  let parsedId = {};
  let parsedIds = [];

  const clean = str => {
    // @see https://docs.piano.io/event-data/
    const len = 64;

    str = str.replace(/[^A-Za-z0-9 =@+\-_.|]/g, '');
    str = str.substring(0, len);

    return str;
  };

  dmps.forEach(dmp => {
    dmp.ids.forEach(id => {
      parsedId = getValue(id);
      if (parsedId.value) {
        parsedId = {
          ...parsedId,
          value: id.hash ? sha256(parsedId.value) : clean(parsedId.value),
        };
        parsedIds.push(parsedId);
      }
    });
    dmp.parsedIds = parsedIds;
    parsedIds = [];
  });

  return dmps;
};
export default addUserIds;
