const getLangIdFromUrl = langIds => {
  if (typeof langIds !== 'object') {
    return langIds;
  }

  const url = document.location.href;
  // we only look up the lang codes in the URL from the settings file
  const langs = Object.keys(langIds).join('|');
  // template literal: /(\/|=)(fr|nl|en)(\/|#|\.|\?|$)/g
  // works on "https://www.mediahuis.be/fr/detailpage" or "https://fr.mediahuis.be"
  const regex = new RegExp(`(/|=)(${langs})(/|#|\\.|\\?|$)`, 'gi');
  const result = regex.exec(url);

  return result ? langIds[result[2]] : langIds[Object.keys(langIds)[0]];
};

export default getLangIdFromUrl;
