const createScript = src =>
  new Promise((resolve, reject) => {
    const id = 'cx';

    if (typeof document === 'undefined' || document.getElementById(id)) {
      resolve();
    } else {
      const scriptTag = document.createElement('script');
      const node = document.getElementsByTagName('script')[0];
      scriptTag.src = src;
      scriptTag.id = id;
      scriptTag.onload = resolve;
      scriptTag.onerror = reject;
      scriptTag.async = true;
      node.parentNode.insertBefore(scriptTag, node);
    }
  });

export default createScript;
