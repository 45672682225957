/* global process */

/**
 * Simple console.log function for not production builds
 *
 */
const log = (...args) => {
  if (process.env.NODE_ENV !== 'production') {
    const stack = String(new Error().stack).split('\n');
    let column = '';
    let lineNumber = '';
    if (stack.length >= 2) {
      const matches = stack[2].match(/(\d+):(\d+)\)?$/);
      if (matches && matches.length >= 3) {
        lineNumber = matches[1];
        column = matches[2];
      }
    }

    const limit = 150;

    if (args[0].length > limit) {
      args[0] = `${args[0].substring(0, limit)}...`;
    }

    /* eslint-disable no-console */
    console.log(
      '%cCxense',
      'display: inline-block; color: #fff; background: #3bc388; padding: 1px 4px; border-radius: 3px;',
      ...args,
      `L: ${lineNumber}, C: ${column}`
    );
    /* eslint-enable no-console */
  }
};

export default log;
