import Storage from './Storage';
import log from './logger';

const getCachedSegments = (name, type) => {
  // becomes redundant when sending them from enriched object
  // example: DMP[0].parsedSegments
  let segmentIds = [];

  const rawSegmentIds = Storage.get(name, type);
  rawSegmentIds
    ? (segmentIds = segmentIds.concat(JSON.parse(rawSegmentIds)))
    : log(`segment key ${name} not found in ${type}`);

  return segmentIds;
};

export default getCachedSegments;
