import log from './logger';

/**
 * This class sets, gets and deletes entries
 * in localStorage and sessionStorage.
 */

class Storage {
  static set = (key, value, type) => {
    if (type === 'localStorage') {
      if (window.localStorage && window.localStorage.setItem) {
        try {
          window.localStorage.setItem(key, value);
          log(`set ${key}=${value} to local storage`);
        } catch (err) {
          log(`unable to set ${key} to local storage`);
          log(err);
        }
      }
    } else if (type === 'sessionStorage') {
      if (window.sessionStorage && window.sessionStorage.setItem) {
        try {
          window.sessionStorage.setItem(key, value);
          log(`set ${key}=${value} to session storage`);
        } catch (err) {
          log(`unable to set ${key} to session storage`);
          log(err);
        }
      }
    }

    return null;
  };
  static get = (key, type) => {
    let value;
    if (type === 'localStorage') {
      if (window.localStorage && window.localStorage.getItem) {
        try {
          value = window.localStorage.getItem(key);
          log(`get ${key}=${value} from local storage`);
        } catch (err) {
          log(`unable to get ${key} from local storage`);
          log(err);
        }
      }
    } else if (type === 'sessionStorage') {
      if (window.sessionStorage && window.sessionStorage.setItem) {
        try {
          value = window.sessionStorage.getItem(key);
          log(`get ${key}=${value} from session storage`);
        } catch (err) {
          log(`unable to get ${key} from session storage`);
          log(err);
        }
      }
    }

    return value;
  };
  static del = (key, type) => {
    if (type === 'localStorage') {
      if (window.localStorage && window.localStorage.removeItem) {
        try {
          window.localStorage.removeItem(key);
          log(`delete ${key} from local storage`);
        } catch (err) {
          log(`unable to delete ${key} from local storage`);
          log(err);
        }
      }
    } else if (type === 'sessionStorage') {
      if (window.sessionStorage && window.sessionStorage.removeItem) {
        try {
          window.sessionStorage.removeItem(key);
          log(`delete ${key} from session storage`);
        } catch (err) {
          log(`unable to delete ${key} from session storage`);
          log(err);
        }
      }
    }

    return null;
  };
}

export default Storage;
