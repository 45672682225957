import log from './logger';

const addExternalIds = dmps => {
  dmps.forEach(dmp => {
    dmp.parsedIds.forEach(pid => {
      window.cX.callQueue.push([
        'addExternalId',
        { id: pid.value, type: dmp.prefix },
      ]);
      log(`add externalId ${pid.value} on ${dmp.prefix}`);
    });
  });
};

export default addExternalIds;
