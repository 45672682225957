import log from './logger';

const cookieSyncs = ssps => {
  ssps.forEach(ssp => {
    window.cX.callQueue.push(['sync', ssp]);
    log(`add cookiesync for ${ssp.partner ? ssp.partner : ssp} to queue`);
  });
};

export default cookieSyncs;
