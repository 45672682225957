import log from './logger';
import Storage from './Storage';

const getValue = obj => {
  let split;
  let value = null;
  let key = obj.name;
  switch (obj.type) {
    case 'object':
      split = obj.name.split('.');
      key = split[split.length - 1];
      value = window[split[0]];
      split.forEach((val, index) => {
        if (index > 0) {
          try {
            value = value[split[index]];
          } catch (err) {
            log(err);
          }
        }
      });
      break;
    case 'localStorage':
      value = Storage.get(obj.name, 'localStorage');
      break;
    case 'cookie':
      if (document.cookie && obj.name) {
        const name = `${obj.name}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            value = c.substring(name.length, c.length);
          }
        }
      }
      break;
    case 'url':
      value = document.location.search;
      if (value) {
        value = value.match(`[\\?&]${obj.name}=([^\\?&]+)`);
        value ? (value = value[1]) : (value = null);
      }
      break;
    case 'dataAttribute':
      try {
        const script = document.querySelector(`script[${obj.name}]`);
        value = script && script.getAttribute(obj.name);
      } catch (err) {
        log(err);
      }
      break;
    case 'static':
      split = obj.name.split(':');
      if (split.length > 1) {
        key = split[0];
        value = split[1];
      }
      break;
    default:
    // do nothing
  }

  // we are allready logging in the Storage class
  if (obj.type !== 'localStorage') {
    log(`get ${obj.name}=${value} from ${obj.type}`);
  }

  return { key, value };
};

export default getValue;
